<template v-if="vEntorno">
  <div v-if="vEntorno">
    <v-row>
      <v-col cols="12" style="padding-bottom: 0">
        <div style="display: flex">
          <v-select
            v-model="vEntorno.id_place"
            :items="vEntorno.allPlace"
            item-text="eventplace"
            label="Sitios disponibles"
            outlined
            return-object
            @change="selectPlace()"
          ></v-select>
        </div>
      </v-col>

      <v-col cols="12" md="6" style="padding-top: 0">
        <div class="addevent eventl" style="width: max-content">
          <newSitio />
        </div>
      </v-col>
      <v-col cols="12" md="6" style="padding-top: 0" v-if="vEntorno.id_place">
        <div class="addevent eventr">
          <newEvent />
        </div>
      </v-col>

      <v-col cols="12">
        <v-card>
          <div>
            <v-row>
              <v-col cols="12" md="6">
                <div class="mx-4"  v-if="vEntorno.id_place">
                  <newSitio :id_place="vEntorno.id_place.id_place" />
                </div>
              </v-col>
              <v-col cols="12" md="6" style="padding: 0px 20px 20px 20px">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <v-data-table
            :headers="headers"
            :items="events"
            :search="search"
            class="row-pointer"
            @click:row="OpenEvent"
          >
            <template v-slot:[`item.img`]="{ item }">
              <div style="padding: 10px">
                <v-img :src="item.img" class="box"></v-img>
              </div>
            </template>

            <template v-slot:[`item.event_config`]="{ item }">
              {{ GetEventsConfig(item.event_config) }}
            </template>

            <template v-slot:[`item.on_create`]="{ item }">
              {{ fixDate(item.on_create) }}
            </template>

            <template v-slot:[`item.activo`]="{ item }">
              <v-switch
                v-model="item.activo"
                inset
                color="success"
                @change="changeSwitch(item)"
              ></v-switch>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="DeleteEvent(item)"> mdi-delete </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogConfig"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      v-if="dialogConfig"
    >
      <v-card flat v-if="MyEvent">
        <v-toolbar dark color="primary">
          <v-toolbar-title
            ><div style="display: flex" v-if="item">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div class="mr-5" v-bind="attrs" v-on="on">
                    <v-icon>mdi-bank</v-icon>
                    {{ item.id_place }}
                  </div>
                </template>
                <span>ID del Lugar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-icon>mdi-codepen</v-icon>
                    {{ item.id_event }}
                  </div>
                </template>
                <span>ID del Evento</span>
              </v-tooltip>
            </div></v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="ClosedialogConfig">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <div style="display: flex; justify-content: space-evenly;">
          <v-card flat style="padding: 20px; max-width: 1300px; width: 100%; margin-top: 15px;">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  label="Nombre del evento"
                  placeholder="Escribe un nombre de evento"
                  v-model="MyEvent.name"
                  hint="Ejemplo: Parador Chichen Itza"
                  persistent-hint
                  outlined
                  clearable
                ></v-text-field>
                <div style="padding: 10px">
                  <strong>Tipo de evento: </strong>&nbsp; EVENTO REGULAR
                </div>
                <div style="padding: 10px">
                  <strong>Forma de cobro:</strong> NETPAY
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <div style="text-align: center">
                  <strong>Imagen de Portada</strong>
                  <v-divider></v-divider>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: space-around;
                    padding-top: 10px;
                  "
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        class="box2 pointman"
                        :style="'background-image: url(' + item.img + ');'"
                        @click="UploadImg(item)"
                      ></div>
                    </template>
                    <span>Subir / Cambiar Portada</span>
                  </v-tooltip>
                </div>

                <v-file-input
                  ref="fileup"
                  type="file"
                  id="coverpicker"
                  accept=".png, .jpg, .webp, .jpeg"
                  v-model="files"
                  @change="readURL(item)"
                  style="display: none"
                />
              </v-col>
            </v-row>
            <EventType1
              ref="EventType1X"
              v-if="MyEvent.event_config == 3"
              :MyEvent="MyEvent"
            />
            <EventType2
              ref="EventType1X"
              v-if="MyEvent.event_config == 4"
              :MyEvent="MyEvent"
            />
          </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import $ from "jquery";

import EventType1 from "../components/EventType1.vue";
import EventType2 from "../components/EventType2.vue";

import newSitio from "../components/newSitio.vue";
import newEvent from "../components/newEvent.vue";

export default {
  name: "EventP1",
  components: {
    EventType1,
    EventType2,
    newSitio,
    newEvent,
  },
  data() {
    return {
      switchActivate: false,
      MyEvent: null,
      dialogConfig: false,
      search: "",
      headers: [
        { text: "Activo", value: "activo" },
        {
          text: "Nombre del evento",
          align: "start",
          value: "name",
        },
        { text: "Tipo de Evento", value: "event_config" },
        { text: "Portada", value: "img", align: "center" },
        { text: "Forma de cobro", value: "payment" },
        { text: "Creado", value: "on_create", align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      files: null,
      vEntorno: null,
      events: [],
      item: null,
      id_place: null,
    };
  },
  created() {
    this.vEntorno = this.$vEntorno;
  },
  mounted() {
    this.vEntorno = this.$vEntorno;
    if (this.vEntorno) {
      if(this.vEntorno.id_place){
        this.GetEvents(this.vEntorno.id_place);
      }
    }
  },
  methods: {
    DeleteEvent(item) {
      this.switchActivate = true;
    },
    ClosedialogConfig() {
      this.dialogConfig = false;
    },
    OpenEvent(item) {
      console.log("evet");
      if (!this.switchActivate) {
        this.MyEvent = item;
        this.dialogConfig = true;
        /* setTimeout(() => {
          this.$refs.EventType1X.load(item)  
        }, 100); */
      } else {
        this.switchActivate = false;
      }
    },
    changeSwitch(item) {
      let d = item.activo;
      this.switchActivate = true;
      this.$swal({
        icon: "warning",
        title: "Deseas desactivar el evento?",
        text: "Esta accion hara que su evento no se muestre en su WebShop",
        showDenyButton: false,
        showCancelButton: true,
        confirmButtonText: "Si",
        denyButtonText: `Don't save`,
        cancelButtonText: `No`,
      }).then((result) => {
        if (result.isConfirmed) {
          item.activo = d;
        } else if (result.isDenied) {
        }
      });

      for (var x = 0; x < 100; x++) {
        setTimeout(() => {
          item.activo = !d;
        }, x);
      }
    },
    fixDate(date) {
      let x = date;
      if (date) {
        x = date.split("T");
        x = x[0];
      }
      return x;
    },
    UploadImg(item) {
      $("#coverpicker").click();
      console.log(item);
    },
    selectPlace() {
      this.GetEvents(this.vEntorno.id_place);
    },
    GetEvents(place) {
      this.events = [];

      if (!place) {
        if (this.vEntorno.allPlace) {
          if (this.vEntorno.allPlace[0]) {
            place = this.vEntorno.allPlace[0];
            this.$set(this.$vEntorno, "id_place", place);
            this.$vEntorno.id_place = place;
          }
        }
      }
      this.$root
        .post("/Admin/GetEvents", { id_place: place.id_place })
        .then((response) => {
          if (response.data) {
            if (response.data.Data) {
              let info = response.data.Data;
              console.log(info);

              this.events = info;

              //console.log(this.$root.event_sel);

              if (this.$root.event_sel) {
                let index = info.findIndex(
                  (item) => item.id_event === this.$root.event_sel
                );
                this.item = info[index];
              } else {
                this.item = info[0];
              }
            }
          }
        });
    },
    GetEventsConfig(idEvent) {
      let res = this.$root.event_config_full.find(
        (elem) => elem.event_config === idEvent
      );
      if (res) {
        res = res.name;
      } else {
        res = "Sin configurar";
      }
      return res;
    },
    readURL(item) {
      if (item) {
        console.log(this.files);
        item.img = URL.createObjectURL(this.files);
      }
    },
  },
};
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.addevent {
  position: relative;
  top: -14px;
}
.eventr {
  text-align: right;
}
.eventl {
  text-align: left;
}
.box {
  border-radius: 5px;
  width: 120px;
  height: 70px;
  border: 0px solid #3f414a;
}
.box2 {
  position: relative;
  border-radius: 5px;
  width: 240px;
  height: 140px;
  background: #fff;
  box-shadow: 0 0 15px rgb(0 0 0 / 10%);
  border: 2px solid #3f414a;
  background-size: cover;
  background-position: 100% 100%;
}
@media (max-width: 500px) {
  .addevent {
    text-align: center;
  }
}
</style>