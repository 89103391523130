<template>
  <v-card flat>
    <h2 color="Mytext"> Configuración Netpay</h2>
    <hr>
    <v-row>
        <v-col>
            <v-text-field
            label="Nombre Lugar del evento"
            placeholder="Escribe el nombre del lugar del evento"
            v-model="place"
            outlined
            ></v-text-field>
        </v-col>
        <v-col>
            
        </v-col>
        <v-col>
            
        </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>