<template>
  <div class="background" :style="{'background-color': background}">
    <div class="divIcon">
        <v-icon class="Vicon" :style="{'color': colorIcon + ' !important'}">
            {{icon}}
        </v-icon>
    </div>
    <div class="Title">
        {{title}}
    </div>
    <div align="center">
        <hr class="new3" :style="{'border-top':'1px solid ' + colorIcon + ' !important'}">
    </div>
    <div class="Subtitle">
        {{subtitle}}
    </div>
  </div>
</template>

<script>
export default {
    name:'MyCard2',
    data(){
        return{

        }
    },
    props:["title","subtitle","icon","background","colorIcon"]
}
</script>

<style scoped>
    .background{
        width: 200px;
        min-height: 300px;
        overflow: hidden;
        position: relative;
        border-radius: 5px;
        box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px -6px rgba(0,0,0,0.75);
    }
    .background .divIcon{
        transform: rotate(315deg);
        top: -37px;
        position: relative;
        left: calc(100% - 158px);
        opacity: 0.6;
        transition: all 0.5s;
    }
    .background .divIcon .Vicon{
        font-size: 180px; 
    }
    .background .Title{
        text-align: center;
        color: white;
        position: relative;
        font-size: 20px;
        margin-top: -30px;;
    }
    .background .Subtitle{
        text-align: center;
        color: white;
        font-size: 14px;
        padding: 10px
    }
    .background hr.new3 {
        border-width: 0;
        width: 80%;
        margin: 10px;
    }
    .background:hover{
        cursor: pointer;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
    }
    .background:hover .divIcon{
        transform: rotate(360deg);
        text-align: center;
        left:5px;
        height: 60px;
        top: 10px;
    }
    .background:hover .divIcon .Vicon{
        font-size: 120px; 
    }
    .background:hover .divIcon, .background:hover hr.new3{
        opacity: 1;
    }
    .background:hover .Title{
        position:sticky;
        margin-top: 75px;
    }
</style>