<template>
  <v-dialog v-model="dialogNewSitio" max-width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark color="primary" v-bind="attrs" v-on="on" v-if="!id_place">
        Agregar sitio
      </v-btn>
      <v-icon
        class="ml-1 mb-1"
        v-bind="attrs"
        v-on="on"
        v-if="id_place"
        @click="loadData()"
      >
        mdi-pencil
      </v-icon>
    </template>

    <v-card flat style="overflow: hidden">
      <v-card-title class="text-h5 grey lighten-2">
        <div v-if="id_place">Modificar sitio</div>
        <div v-if="!id_place">Agregar sitio</div>
      </v-card-title>
      <v-row style="padding: 20px 20px">
        <v-col cols="12" v-if="vEntorno">
          <v-text-field
            label="Nombre del sitio"
            placeholder="Escribe del sitio"
            v-model="placeX.name"
            hint="Ejemplo: Parador Chichen Itza"
            persistent-hint
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nombre del la ciudad / municipio"
            placeholder="Escribe de la ciudad / municipio"
            v-model="placeX.state"
            hint="Ejemplo: Mérida"
            persistent-hint
            outlined
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">  
          <v-text-field
            label="Nombre del estado"
            placeholder="Escribe del estado"
            v-model="placeX.city"
            hint="Ejemplo: Yucatan"
            persistent-hint
            outlined
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="error" @click="dialogNewSitio = false"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="dialogNewSitio = false"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "newSitio",
  data() {
    return {
      dialogNewSitio: false,
      minh: "300px",
      vEntorno: null,
      placeX: { state: null, city: null, id_place: null, name: "" },
    };
  },
  props: ["id_place"],
  mounted() {
    this.vEntorno = this.$vEntorno;
  },
  methods: {
    loadData() {
      if (this.id_place) {
        let item = this.vEntorno.id_place;
        this.placeX = {
          state: item.state,
          city: item.city,
          id_place: item.id_place,
          name: item.name,
        };
      }
    },
  },
};
</script>

<style scoped>
</style>