<template>
  <Dashboard2/>
</template>

<script>
import Dashboard2 from '../components/Dashbord.vue'
export default {
  name:'Dashboard',
    components:{
        Dashboard2
    }
}
</script>

<style>

</style>