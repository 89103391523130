<template>
  <v-dialog v-model="dialogNewSitio" max-width="900">
    <template v-slot:activator="{ on, attrs }">
      <v-btn dark color="primary" v-bind="attrs" v-on="on">
        Agregar evento
      </v-btn>
    </template>

    <v-card flat style="overflow: hidden">
      <v-card-title class="text-h5 grey lighten-2">
        <div>Agregar evento</div>
      </v-card-title>

      <v-row style="padding: 20px 20px">
        <v-col
          cols="12"
          md="6"
          style="min-height: 215px !important"
          v-if="vEntorno"
        >
          <v-select
            v-model="event"
            :items="event_config"
            item-text="name"
            label="Tipo de evento"
            outlined
            return-object
            style="padding-top: 10px !important"
          ></v-select>

          <v-text-field
            label="Nombre del evento"
            placeholder="Escribe un nombre de evento"
            v-model="name_event"
            hint="Ejemplo: Parador Chichen Itza"
            persistent-hint
            outlined
            clearable
          ></v-text-field>

          <v-select
            v-model="cata"
            :items="vEntorno.catalogue"
            item-text="name"
            label="Categoria del evento"
            outlined
            return-object
            style="padding-top: 20px !important"
          ></v-select>
        </v-col>
        <v-col class="Colcernter ContentImg">
          <div style="width: 100%;" align="center">
            <v-img
              src="../assets/free2.png"
              style="width: 400px !important; height: 189px !important"
              v-if="event.event_config === 3"
            />
            <v-img
              src="../assets/time.png"
              style="width: 260px !important; height: 180px !important"
              v-if="event.event_config === 4"
            />
          </div>
        </v-col>
        <v-col cols="12" style="padding-top: 0">
          <div v-if="event.event_config === 3" class="t_Content">
            <div>
              <div>
                <h2>Es un evento que cuenta con:</h2>
              </div>
              <div class="itemE">
                <strong>* Venta ilimitada de boletos</strong>
              </div>
              <div class="itemE">
                <strong
                  >* Acceso sin horario de restricción dentro de la hora
                  laboral</strong
                >
              </div>
            </div>
          </div>

          <div v-if="event.event_config === 4" class="t_Content">
            <div>
              <div>
                <h2>Es un evento que cuenta con:</h2>
              </div>
              <div class="itemE">
                <strong>* Se accede en fecha y hora especifica</strong>
              </div>
              <div class="itemE">
                <strong>* Tiene cupo limitado</strong>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn color="error" @click="dialogNewSitio = false"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="dialogNewSitio = false"> Guardar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "newSitio",
  data() {
    return {
      event_config: [],
      name_event: null,
      cata:null,
      event: { event_config: 3, name: "Evento Regular" },
      dialogNewSitio: false,
      vEntorno: null,
    };
  },
  mounted() {
    this.event_config = this.$root.event_config;
    this.vEntorno = this.$vEntorno;
  },
};
</script>

<style>
</style>