import Vue from "vue";

Vue.prototype.$vEntorno = {
    id_place:null,
    allPlace:[],
    catalogue:[],
    EventSelect:{id_event:null, id_place:null},
    Subevents:[],
    Subevents2:[],
    SubEventsTab:0,
    SubEventspanel:0,
}