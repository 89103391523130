<template>
  <div style="padding-top: 10px" v-if="vEntorno">
    <div class="MyTitle">Especificaciones</div>
    <div
      style="position: absolute; margin-top: -30px; left: calc(100% - 104px)"
    >
      <v-btn small color="success" :disabled="actBtnEsp(especifications)"
        >Nuevo</v-btn
      >
    </div>
    <v-divider></v-divider>
    <div align="center">
      <div class="txtColor">
        <strong>Maximo 5 especificaciones</strong>
      </div>
    </div>

    <v-list dense style="padding: 0" v-if="vEntorno.Subevents[index].info">
      <v-list-item-group v-model="selectedItem" color="primary">
        <draggable
          class="items-list"
          v-model="vEntorno.Subevents[index].info.ES"
          draggable=".itemDragList"
        >
          <v-list-item
            v-for="(item, i) in vEntorno.Subevents[index].info.ES"
            :key="i"
            class="itemDragList"
            @mouseup="openinfo(item ,i)"
          >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.info"></v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <div style="display: flex">
                <v-btn icon>
                  <v-icon color="grey lighten-1">mdi-delete</v-icon>
                </v-btn>
              </div>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "shorgallery",
  components: {
    draggable,
  },
  data() {
    return {
      vEntorno:null,
      draggableList: [],
      selectedItem: 1,
      especifications: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience1", icon: "mdi-account" },
        { text: "Audience2", icon: "mdi-account" },
        { text: "Audience3", icon: "mdi-account" },
      ],
    };
  },
  props:["index"],
  mounted(){
    this.vEntorno = this.$vEntorno
  },
  methods: {
    openinfo(item, i){
      let info = {item:item, index:i}
      this.$emit('Especlick', info)
    },
    actBtnEsp(especifications) {
      let x = false;
      if (especifications.length > 4) {
        x = true;
      }
      return x;
    },
  },
};
</script>

<style scoped>
.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
}
.txtColor {
  padding: 0px;
  text-align: right;
  color: red;
  font-size: 10px;
}
</style>