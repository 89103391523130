<template>
  <v-card flat style="margin-top: 10px">
    <v-row>
      <v-col cols="12">
        <div class="mt-n5">
          <div class="Mytab mx-auto">
            <div
              class="tab"
              @click="tab === 0 || changetab(0)"
              :class="tab === 0 ? 'active' : ''"
            >
              DESCRIPCION GENERAL
            </div>
            <div
              class="tab"
              @click="tab === 1 || changetab(1)"
              :class="tab === 1 ? 'active' : ''"
            >
              ACCESO GENERAL
            </div>
            <div
              class="tab"
              @click="tab === 2 || changetab(2)"
              :class="tab === 2 ? 'active' : ''"
            >
              FECHA/HORARIOS
            </div>
            <div
              class="tab"
              @click="tab === 3 || changetab(3)"
              :class="tab === 3 ? 'active' : ''"
            >
              MAPA
            </div>
          </div>
        </div>
      </v-col>
    </v-row>

    <div v-if="tab == 0">
      <div style="padding-top: 20px; padding-bottom: 25px">
        <v-tiptap v-model="textRich">
          <template #mybutton="{ editor }">
            <v-btn
              icon
              small
              title="Clear Content"
              @click="editor.commands.clearContent()"
            >
              <v-icon>mdi-delete-circle-outline</v-icon>
            </v-btn>
          </template>
        </v-tiptap>
      </div>
    </div>

    <div class="my-1" v-if="tab > 0">
      <div class="my-2">
        <v-btn color="success"> NUEVO </v-btn>
      </div>
      <div v-if="vEntorno">
        <v-data-table
          :headers="headers2"
          :items="vEntorno.Subevents"
          :search="search"
          class="row-pointer"
          @click:row="OpenSubEvent"
        >
        </v-data-table>
      </div>
    </div>

    <v-dialog v-model="dialogSubevents" class="MyToolBar" max-width="1200" persistent>
      <v-card flat v-if="vEntorno">
        <v-toolbar color="primary" dark class="MyToolBar" max-width="1200"
          >Subevento
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="ClosedialogConfig">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-row
          v-if="vEntorno.Subevents2[index]"
          style="padding: 100px 30px 30px 30px"
        >
          <v-col cols="12" style="padding-top: 0px">
            <div class="MyTitle">Sub Categoria</div>
            <v-divider></v-divider>

            <div style="padding-top: 20px">
              <v-text-field
                label="Nombre del categoria"
                placeholder="Escribe un nombre de la categoria"
                v-model="vEntorno.Subevents2[index].title"
                hint="Ejemplo: Parador Chichen Itza"
                persistent-hint
                outlined
                clearable
              ></v-text-field>
            </div>
            <div style="padding-top: 10px">
              <v-textarea
                v-model="vEntorno.Subevents2[index].description"
                outlined
                :counter="250"
                :rules="[
                  (v) =>
                    (v && v.length <= 250) ||
                    'La descripción debe tener 250 caracteres o menos.',
                ]"
                label="Descripción la categoria"
                height="100"
                no-resize
                outline
              >
              </v-textarea>
            </div>
          </v-col>

          <v-col cols="12" style="padding-top: 0px">
            <div>
              <ListEspecif :index="index" @Especlick="Especlick" v-if="ListEspecifShow"/>
            </div>
          </v-col>

          <v-col cols="12" style="padding-top: 0; padding-bottom: 22px">
            <div style="width: 100%">
              <shortGallery :index="index" />
            </div>
          </v-col>

          <v-col cols="12" v-if="vEntorno">
            <div class="MyTitle">Boletos</div>
            <div
              style="
                position: absolute;
                margin-top: -30px;
                left: calc(100% - 122px);
              "
            >
              <v-btn small color="success">Vincular</v-btn>
            </div>

            <v-divider></v-divider>

            <v-data-table
              :headers="headers"
              :items="vEntorno.Subevents2[index].tickets"
              :search="search"
              style="padding-top: 10px"
              v-if="vEntorno.Subevents2[index]"
            >
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon @click="DeleteEvent(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEspeciX" max-width="600px" class="MyToolBar">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Especificacion
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="defCountry"
                :items="options"
                item-text="icon"
                :prepend-icon="defCountry.icon"
                label="Select"
                persistent-hint
                return-object
              >
                <template v-slot:item="{ item }">
                  <v-avatar left>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-avatar>
                    {{ item.icon }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-text-field v-model="item_especifica.info"></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="primary" @click="Setitem()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import ListEspecif from "./Tools/ListEspecifi.vue";
import shortGallery from "./Tools/ShortGallery.vue";
import $ from "jquery";

export default {
  name: "EventType1",
  components: {
    shortGallery,
    ListEspecif,
  },
  data() {
    return {
      ListEspecifShow:true,
      defCountry: { icon: "" },
      options: [
        { icon: "" },
        { icon: "mdi-clock" },
        { icon: "mdi-calendar-check" },
        { icon: "mdi-alert-circle" },
      ],
      item_especifica: {info:'',icon:null,index:null},
      dialogEspeciX: false,
      vEntorno: null,
      textRich: null,
      descrip: null,
      dialogSubevents: false,

      tab: 0,
      tabs: [],
      length: 3,
      panel: 0,

      headers: [
        {
          text: "Nombre del evento",
          align: "start",
          value: "alias",
        },
        { text: "Stock", value: "stock", align: "center" },
        { text: "Precio", value: "price" },
        { text: "Activo", value: "active", align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      headers2: [
        {
          text: "Nombde de subcategoria",
          align: "start",
          value: "title",
        },
        { text: "Activo", value: "active", align: "center" },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],

      tickets: [],
      search: null,
      information: null,
      index: 0,
    };
  },
  props: ["MyEvent"],
  mounted() {
    this.vEntorno = this.$vEntorno;
    this.fixRichText();
    this.load(this.MyEvent);
  },
  methods: {
    Setitem(item){
      this.vEntorno.Subevents[this.index].info.ES[this.item_especifica.index].icon = this.defCountry.icon
      this.vEntorno.Subevents[this.index].info.ES[this.item_especifica.index].info = this.item_especifica.info

      this.dialogEspeciX = false;
      this.$set(this.$vEntorno, "Subevents", this.vEntorno.Subevents);
      
    },
    Especlick(item) {
      this.item_especifica = {info:item.item.info, icon:item.item.icon, index:item.index},
      this.defCountry = { icon: item.item.icon };
      this.dialogEspeciX = true;
    },
    ClosedialogConfig() {
      this.dialogSubevents = false;
    },
    OpenSubEvent(item, index) {
      this.index = index.index;
      this.$set(
        this.$vEntorno,
        "Subevents2",
        JSON.parse(JSON.stringify(this.vEntorno.Subevents))
      );
      this.dialogSubevents = true;

      setTimeout(() => {
        $(".SubevetsTop").scrollTop(0);
      }, 10);
    },
    DeleteEvent(item) {},
    actBtnEsp(especifications) {
      let x = false;
      if (especifications.length > 3) {
        x = true;
      }
      return x;
    },
    load(item) {
      this.$root
        .post("/Admin/GetSubCategorias", { event: item })
        .then((response) => {
          if (response.data) {
            let info = response.data;
            //console.log(info);
            this.information = info;
            this.textRich = info.acercade;
          }
        });
    },
    fixRichText() {
      setTimeout(() => {
        $(".ProseMirror").addClass("MyScroll");
      }, 100);
    },
    ediTitle() {
      this.panel = 1;
    },
    getSubEventData() {
      console.log('getSubEventData');
      if(!this.information.category){
        this.$set(this.$vEntorno, "Subevents", [
          {
            description: null,
            id_cataloge: null,
            img: [],
            info: { info1: null, title: null },
          },
        ]);
        return false
      }
      let resul = this.information.category.find(
        (elem) => elem.id_category === this.tab - 1
      );
      if (resul) {
        for (var x = 0; x < resul.cate.length; x++) {
          if (resul.cate[x].info) {
            if (typeof resul.cate[x].info != "object") {
              resul.cate[x].info = JSON.parse(resul.cate[x].info);
            }
          }
        }
        //console.log(resul.cate);

        this.$set(this.$vEntorno, "Subevents", resul.cate);
      } else {
        this.$set(this.$vEntorno, "Subevents", [
          {
            description: null,
            id_cataloge: null,
            img: [],
            info: { info1: null, title: null },
          },
        ]);
      }
    },
    changetab(tab) {
      this.tab = tab;
      switch (tab) {
        case 0:
          this.fixRichText();
          break;
        case 1:
        case 2:
        case 3:
          this.getSubEventData();

          break;
      }
    },
  },
};
</script>

<style scoped>
.MyToolBar {
  position: fixed;
  width: 100%;
  height: 76px !important;
  z-index: 1;
  padding: 10px;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.pointman:hover {
  cursor: pointer;
}

.MyTitle {
  text-align: left;
  font-weight: 800;
  color: #6e6e6e;
}
.txtColor {
  padding: 10px;
  text-align: center;
  color: red;
  font-size: 14px;
}
.BtnNewGalley {
  display: flex;
  align-content: space-between;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}
.Mytab {
  font-size: 12px;
  display: flex;
  width: 100%;
  border-bottom: 2px solid #252525;
}
.Mytab .tab {
  padding: 8px 10px;
  border-radius: 5px 5px 0px 0px;
  font-weight: bold;
  letter-spacing: 2px;
}
.Mytab .tab:hover:not(.active) {
  background: rgb(73, 69, 69);
  color: black;
  cursor: pointer;
  color: white;
}
.Mytab .active {
  background: #252525;
  color: white;
}
.BtnImg {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  zoom: 80%;
}
@media (max-width: 1301px) {
  .Mytab {
    margin-left: 30px !important;
    margin-right: 30px !important;
    width: auto;
  }
}
@media only screen and (max-width: 700px) {
  .Mytab {
    display: flex;
    flex-direction: column;
    margin-left: 15px !important;
    margin-right: 15px !important;
    border-bottom: 0px;
    border-left: 2px solid #252525;
  }

  .Mytab .tab {
    border-radius: 0px 5px 5px 0px;
  }
}
</style>