<template>
  <v-app
    id="app"
    class="noselect"
    style="width: 100vw;"
  >
  <router-view/>
    <!-- <div class="MyAlertNotification" v-if="$root.ShowAlert">
      <v-alert
      :color="$root.Coloralert"
      dark
      :icon="$root.IconAlert"
      border="left"
      prominent
      style="zoom:80%"
    >
      {{$root.MsgAlert}}
    </v-alert>
    </div> -->
  </v-app>
</template>

<script>

import $ from "jquery";
export default {
  components: {
    
  },
  data() {
    return {
      Scroll: "orange",
    };
  },
  created(){
    localStorage.setItem("vEntorno1", null)
    localStorage.setItem("vEntorno2", null)

    document.documentElement.style.setProperty("--color-black", "#212121");
    document.documentElement.style.setProperty("--azul-fuerte", "#41609d");
    document.documentElement.style.setProperty("--black", "#000");
    document.documentElement.style.setProperty("--white", "#ebebeb");

    console.log("%cDetente!!","color:red;font-size: 24px;font-weight: bold;");
    console.log('%cEsta función del navegador está pensada para desarrolladores. Si alguien te ha indicado que copiaras y pegaras algo aquí eliminaremos tu cuenta, o procederemos legalmente ya que se trata de un fraude.',"font-size: 18px;")
  },
  async mounted() {
    localStorage.setItem("vEntorno1", null)
    localStorage.setItem("vEntorno2", null)

    this.setColorApp()
    let token = JSON.parse(localStorage.getItem("user"));

    this.setentorno();
    this.$root.token = token;
    let mobil = await this.$root.detectmobil()
    if(!mobil){
      $("html").css({ overflow: "hidden" });
    }
  },
  methods: {
    setentorno() {
      let vEntorno = JSON.parse(localStorage.getItem("vEntorno"));

    },
    setColorApp: function() {
    }
  },
};
</script>

<style>
.MyScroll{
  overflow: auto !important;
  height: 300px !important;
}

.MyLoading {
  position: absolute;
  z-index: 9999999;
  top: 0;
  left: 0;
  height: 100 vh;
  width: 100 vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-text);
  overflow: hidden;
  /* max-width: 100vw;
  max-height: 100vh; */
  background: black;
}
.login{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  background: #e5e5e5;
  /*  background: rgb(139,139,139); */
  /* background: linear-gradient(165deg, rgba(204,204,204,1) 0%, rgba(130,130,130,1) 51%, rgba(204,204,204,1) 93%); */
  background: linear-gradient(
    165deg,
    var(--color-primary-light1)  0%,
    var(--color-primary-light) 40%,
    var(--color-primary-light) 45%,
    var(--color-primary)  93%
  )
}
* {
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: var(--color-primary) transparent;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 0px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--color-primary) !important;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary-hover) !important;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 5px;
  }
}
</style>
