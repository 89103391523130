<template>
  <v-card flat style="height: 100vh; background: #f7f7f7; overflow: auto">
    <mytoolbar/>
    <v-card style="margin: -40px 40px 10px 40px; padding:20px;">
      <v-card flat style="padding-top: 10px">
        <EventP1 />
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import $ from "jquery";
import mytoolbar from "../components/mytoolbar.vue"
import EventP1 from "../components/EventP1.vue";

export default {
  name: "Events",
  components: {
    EventP1,
    mytoolbar,
  },
  data() {
    return {
      vEntorno: null,

      switch2: false,
      tab: 1,
      event_config: [],
    };
  },
  created() {
    this.event_config = this.$root.event_config_full;
  },
  mounted() {},
  methods: {
    
  },
};
</script>

<style>
.pointman:hover {
  cursor: pointer;
}
</style>