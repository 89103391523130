<template>
  <div v-if="vEntorno">
    <div style="padding: 0px 0px 10px;">
      <div class="MyTitle">Galeria</div>
      <div
        style="position: absolute; margin-top: -30px; left: calc(100% - 122px)"
      >
        <v-btn small color="success" :disabled="actBtnImg()">Agregar</v-btn>
      </div>
      <v-divider></v-divider>

      <div align="center">
        <div class="txtColor">
          <strong>Maximo 6 imagenes</strong>
        </div>
      </div>
    </div>

    <draggable
      class="items-list"
      v-model="vEntorno.Subevents[index].img"
      style="display: flex; flex-flow: row wrap; justify-content: space-around"
      draggable=".itemDragImg"
    >
      <div
        v-for="(item, index) in vEntorno.Subevents[index].img"
        :key="'ShortImg-' + index"
        style="padding: 5px"
        class="itemDragImg pointman"
      >
        <v-img
          :src="'https://gpstuuxan.com/axess/gallery/'+item.name"
          :lazy-src="'https://gpstuuxan.com/axess/gallery/'+item.name"
          aspect-ratio="1"
          class="grey lighten-2"
          height="150"
          width="150"
          style="border-radius: 10px"
          @click="UploadImg()"
        >
          <div class="BtnImg">
            <div class="mx-1 my-1" style="text-align: right;">
              <v-btn
                class="mx-2"
                fab
                small
                dark
                color="error"
                @click="DeleteImg()"
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </div>
          </div>

          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </div>
      
    </draggable>
    <v-file-input
      ref="fileup"
      type="file"
      id="refreshImg"
      accept=".png, .jpg, .webp, .jpeg"
      v-model="files"
      @change="readURL()"
      style="display: none"
    />
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "shorgallery",
  components: {
    draggable,
  },
  data() {
    return {
      vEntorno:null,
      draggableImg: [],
      files: null,
      flagImgEdit: false,
    };
  },
  props:["index"],
  mounted(){
    this.vEntorno = this.$vEntorno
  },
  methods: {
    actBtnImg() {
      let x = false;
      if (this.vEntorno.Subevents[this.index].img.length > 5) {
        x = true;
      }
      return x;
    },
    readURL(item) {
      if (item) {
        console.log(this.files);
        item.img = URL.createObjectURL(this.files);
      }
    },
    UploadImg(item) {
      if (!this.flagImgEdit) {
        $("#refreshImg").click();
      } else {
        this.flagImgEdit = false;
      }
    },
    DeleteImg() {
      this.flagImgEdit = true;
    },
  },
};
</script>

<style scoped>
.txtColor {
  padding: 0px;
  text-align: right;
  color: red;
  font-size: 10px;
}
.btnNImg {
  width: 50%;
  text-align: center;
  padding-top: 40px;
}
</style>