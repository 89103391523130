import axios from "axios";

class AuthService {
  login(user) {
    return axios
      .post(user.myserver + user.myport + "/login/", {
        email: user.email,
        password: user.password,
      })
      .then((resp) => {
        if(resp.data.Data){
          if (resp.data.Data[0]) {
            resp.data.Data = resp.data.Data[0]
            resp.data.ini = 0
            localStorage.setItem("user", JSON.stringify(resp.data));
          }
        }else{
          localStorage.setItem("user", JSON.stringify(resp.data));
        }
        return resp.data;
      });
  }
  logout() {
    localStorage.removeItem("user");
  }
}

export default new AuthService();
