<template>
  <v-card style="background-color:#171e27 !important; padding:20px; height: 100vh; overflow: auto;" v-if="pm2[0]">
    <v-row>
        <v-col cols="12" md="6">
            <div style="width:100%;display:flex">
                <!-- Assigned Server Name -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="header-Item flexUp">
                            <svg
                            data-v-009a24aa=""
                            data-v-8d6cd78a=""
                            width="24"
                            height="24"
                            class="km-icon-component status-icon-component"
                            title="Server is online: displayed data is in real time"
                            bottom=""
                            viewBox="0 0 24 24"
                            style="color: rgb(7, 164, 255)"
                            >
                            <g fill="none" fill-rule="evenodd">
                                <g transform="translate(2 2)">
                                <rect
                                    width="18.5"
                                    height="4.5"
                                    x=".75"
                                    y=".75"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    rx="1"
                                ></rect>
                                <ellipse
                                    cx="3.135"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                <ellipse
                                    cx="5.635"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                </g>
                                <g transform="translate(2 9)">
                                <rect
                                    width="18.5"
                                    height="4.5"
                                    x=".75"
                                    y=".75"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    rx="1"
                                ></rect>
                                <ellipse
                                    cx="3.135"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                <ellipse
                                    cx="5.635"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                </g>
                                <g transform="translate(2 16)">
                                <rect
                                    width="18.362"
                                    height="4.5"
                                    x=".75"
                                    y=".75"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    rx="1"
                                ></rect>
                                <ellipse
                                    cx="3.12"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                <ellipse
                                    cx="5.602"
                                    cy="3.125"
                                    fill="currentColor"
                                    rx="1"
                                    ry="1"
                                ></ellipse>
                                </g>
                            </g>
                            </svg>
                            <strong>{{pm2[0].pm2_env.COMPUTERNAME}}</strong>
                        </div>
                    </template>
                    <span>Assigned Server Name</span>
                </v-tooltip>

                <!-- Proccess Count -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="header-Item flexUp">
                            <svg
                            data-v-009a24aa=""
                            width="24"
                            height="24"
                            class="km-icon-component"
                            viewBox="0 0 24 24"
                            style="color: rgb(7, 164, 255)"
                            >
                            <g
                                fill="none"
                                fill-rule="evenodd"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.5"
                            >
                                <path
                                d="M11.821 2l4.297 2.481v4.961l-4.297 2.481-4.297-2.481V4.481zM6.297 11.821l4.296 2.481v4.961l-4.296 2.481L2 19.263v-4.961zM17.345 11.821l4.297 2.481v4.961l-4.297 2.481-4.296-2.481v-4.961z"
                                ></path>
                            </g>
                            </svg>
                            <strong>{{pm2[0].pm2_env.instances}}</strong>
                        </div>
                    </template>
                    <span>Proccess Count</span>
                </v-tooltip>

                <!-- Total memory aviable -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="header-Item flexUp">
                            <svg
                            data-v-009a24aa=""
                            width="24"
                            height="24"
                            class="km-icon-component"
                            viewBox="0 0 24 24"
                            style="color: rgb(7, 164, 255)"
                            >
                            <g transform="translate(5 2)" fill="none" fill-rule="evenodd">
                                <rect
                                width="8.187"
                                height="18.056"
                                x="1.063"
                                y=".75"
                                stroke="currentColor"
                                stroke-width="1.5"
                                rx="1"
                                ></rect>
                                <path
                                fill="currentColor"
                                d="M2.7 2.958h4.76v1.25H2.7zM2.7 7.125h4.76v1.25H2.7zM2.7 11.292h4.76v1.25H2.7zM2.7 15.458h4.76v1.25H2.7zM8.917 4.417h2.937v1.25H8.917zM8.917 7.75h2.937V9H8.917zM8.917 11.083h2.937v1.25H8.917zM8.917 14.417h2.937v1.25H8.917z"
                                ></path>
                                <path
                                stroke="currentColor"
                                stroke-width="1.5"
                                d="M9.083 1.583V18h3.01a.25.25 0 0 0 .25-.25V1.833a.25.25 0 0 0-.25-.25h-3.01z"
                                ></path>
                            </g>
                            </svg>
                            <strong>N/A</strong>
                        </div>
                    </template>
                    <span>Total memory aviable</span>
                </v-tooltip>

                <!-- Nodejs version -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="header-Item flexUp">
                            <v-icon class="theme--dark primary--text">
                                mdi-nodejs
                            </v-icon>            
                            <strong>{{pm2[0].pm2_env.node_version}}</strong>
                        </div>
                    </template>
                    <span>Nodejs version</span>
                </v-tooltip>

                <!-- PM2 version -->
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="header-Item flexUp">
                            <v-icon class="theme--dark primary--text">
                                mdi-menu
                            </v-icon>            
                            <strong>{{pm2[0].pm2_env.axm_options.module_version}}</strong>
                        </div>
                    </template>
                    <span>PM2 version</span>
                </v-tooltip>
            </div>
        </v-col>

        <v-col cols="12" md="6" style="display: flex; flex-direction: row-reverse;">
            <!-- Nodejs version -->
            <div style="display:flex" class="header-Item2">  
                <strong>{{ipv6}}</strong><strong>/</strong>
                <strong>{{ipv4}}</strong><strong>/</strong>
                <strong>{{pm2[0].pm2_env.USERDOMAIN}}</strong>
            </div>
        </v-col>
    </v-row>
    <v-row v-for="(item, index) in pm2" :key="'pm2'+index">
        <v-col cols="12" style="color:white; padding-bottom: 0;">
            <div style="background:#202a37; width:100%; display:flex;padding: 10px 0px; flex-wrap: wrap; justify-content: space-between; align-items: center;">
                <div style="display: flex;">
                    <div>
                        <div style="display: flex;">
                            <div style="position: relative; margin-top: -10px;width:48px; height:48px; border:2px solid #171e27 !important; text-align:center; border-left: 0px !important; border-top: 0px !important;">
                                boton
                            </div>
                            <div class="Title1">
                                <svg data-v-009a24aa="" data-v-8d6cd78a="" data-v-0086a1e8="" width="24" height="24" class="km-icon-component status-icon-component pr-1" title="App is online: displayed data is in real time" bottom="" style="color: rgb(7, 164, 255);" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M11.66 2l8.661 5v10l-8.661 5L3 17V7z"></path></svg>
                                {{item.name}}           
                            </div>
                        </div>
                        <div style="padding: 10px 10px 0px 20px;">
                            <div>
                                Id Proccess = {{item.pm2_env.pm_id}}
                            </div>
                            <div>
                                NODE_ENV = {{verificador(item.pm2_env.env.NODE_ENV)}}
                            </div>
                            <div>
                                {{timeoline(item.pm2_env.status, item.pm2_env.pm_uptime)}}
                            </div>
                        </div>
                    </div>
                </div>
                <!-- CPU -->
                <div>
                    <div class="Title1">
                        <svg data-v-009a24aa="" data-v-3c81f518="" width="24" height="24" class="km-icon-component mr-2" viewBox="0 0 24 24"><g stroke="currentColor" stroke-width="1.5" transform="translate(2 2)" fill="none" fill-rule="evenodd"><g stroke-linecap="square"><path d="M8.167.334v2.333M11.833.334v2.333"></path><g><path d="M8.167 17.4v2.333M11.833 17.4v2.333"></path></g></g><g stroke-linecap="square"><path d="M.334 11.834h2.333M.334 8.168h2.333"></path><g><path d="M17.4 11.834h2.333M17.4 8.168h2.333"></path></g></g><rect width="13.167" height="13.167" x="3.417" y="3.417" rx="1"></rect><rect width="4.833" height="4.833" x="7.583" y="7.583" rx="1"></rect></g></svg>
                        <strong>CPU</strong>
                    </div>
                    <div class="Val">
                        <strong>{{item.monit.cpu}}%</strong>
                    </div>
                    </div>
                <!-- Memory -->
                    <div>
                        <div class="Title1">
                            <svg data-v-009a24aa="" data-v-3c81f518="" width="24" height="24" class="km-icon-component mr-2" viewBox="0 0 24 24"><g transform="translate(5 2)" fill="none" fill-rule="evenodd"><rect width="8.187" height="18.056" x="1.063" y=".75" stroke="currentColor" stroke-width="1.5" rx="1"></rect><path fill="currentColor" d="M2.7 2.958h4.76v1.25H2.7zM2.7 7.125h4.76v1.25H2.7zM2.7 11.292h4.76v1.25H2.7zM2.7 15.458h4.76v1.25H2.7zM8.917 4.417h2.937v1.25H8.917zM8.917 7.75h2.937V9H8.917zM8.917 11.083h2.937v1.25H8.917zM8.917 14.417h2.937v1.25H8.917z"></path><path stroke="currentColor" stroke-width="1.5" d="M9.083 1.583V18h3.01a.25.25 0 0 0 .25-.25V1.833a.25.25 0 0 0-.25-.25h-3.01z"></path></g></svg>
                            <strong>Memory</strong>
                        </div>
                        <div class="Val" v-if="item.pm2_env.axm_monitor['Heap Size']">
                            <strong>{{item.pm2_env.axm_monitor["Heap Size"].value}}{{item.pm2_env.axm_monitor["Heap Size"].unit}}</strong>
                        </div>
                    </div>
                <!-- Event Loop Lag -->
                    <div>
                        <div class="Title2">
                            <strong>Event Loop Lag</strong>
                        </div>
                        <div class="Val" v-if="item.pm2_env.axm_monitor['Event Loop Latency']">
                            <strong>{{item.pm2_env.axm_monitor["Event Loop Latency"].value}}{{item.pm2_env.axm_monitor["Event Loop Latency"].unit}}</strong>
                        </div>
                    </div>
                <!-- Http Latency -->
                    <div>
                        <div class="Title1">
                            <svg data-v-009a24aa="" data-v-3c81f518="" width="24" height="24" class="km-icon-component mr-2" viewBox="0 0 24 24"><g stroke="currentColor" stroke-width="1.5" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M5 4.123h14.628M5 12.333h14.628M5 20.544h14.628M12.579 8.228h7.314M12.579 16.439h7.314"></path></g></svg>
                            <strong>Http Latency</strong>
                        </div>
                        <div class="Val">
                            <strong>{{httpfix(item.pm2_env.axm_options.metrics.http)}}</strong>
                        </div>
                    </div>
                <!-- Issus -->
                    <div>
                        <div class="Title1">
                            <svg data-v-009a24aa="" data-v-3c81f518="" width="24" height="24" class="km-icon-component mr-2" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-width="1.5" transform="translate(2 2)"><rect width="11.833" height="16" x="4.083" y="2.417" rx="5.917"></rect><path stroke-linecap="square" d="M19.583 7.5h-2.916M3.333 7.5H.417M14.708 0L12.5 2.357M5 0l2.208 2.357M19.583 10.417h-2.916M11.25 9.167H8.333M11.25 11.667H8.333M3.333 10.417H.417M19.583 13.333h-2.916M3.333 13.333H.417"></path></g></svg>
                            <strong>Issus</strong>
                        </div>
                        <div class="Val">
                            <strong>0</strong>
                        </div>
                    </div>
                <!-- Restarts -->
                <div>
                    <div class="Title1">
                        <svg data-v-009a24aa="" data-v-3c81f518="" width="24" height="24" class="km-icon-component mr-2" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path fill="currentColor" fill-rule="nonzero" d="M5.042 12.858l.005.25a7.424 7.424 0 1 0 7.579-7.58l-.25-.004.028-1.334.25.006a8.757 8.757 0 1 1-8.94 8.94l-.005-.25 1.333-.028z"></path><path stroke="currentColor" d="M14.522 2.708l-2.208 2.209 2.208 2.208"></path></g></svg>
                        <strong>Restarts</strong>
                    </div>
                    <div class="Val">
                        <strong>{{item.pm2_env.restart_time}}</strong>
                    </div>
                </div>
                <!-- Versioning -->
                <div v-if="item.pm2_env.versioning">
                    <div class="Title2">
                        <strong>Versioning</strong>
                    </div>
                    <div class="Title3">
                        <v-icon dark style="font-size:15px;">mdi-link-variant</v-icon>
                        <a :href="item.pm2_env.versioning.url" target="_blank">{{item.pm2_env.versioning.url}}</a>
                    </div>
                    <div class="Title3">
                        <v-icon dark style="font-size:15px;">mdi-source-branch</v-icon>
                         #main - {{item.pm2_env.versioning.revision.substring(0, 12)}} 
                    </div>
                    <div class="Title3">
                        <v-icon dark style="font-size:15px;">mdi-comment</v-icon>
                        "{{item.pm2_env.versioning.comment}} " {{unstaged(item.pm2_env.versioning.unstaged)}}
                    </div>
                </div>

                <div style="padding: 20px 10px 10px 10px;">
                    <v-btn small color="#304552" dark @click="getList()">
                        Restart Apps
                    </v-btn>
                </div>
            </div>
        </v-col>
        <v-col cols="12" style="color:white; padding-top: 0;">
            <div style="display:flex; background: #202a37;flex-wrap: wrap;">
                <div class="icontitle">
                    <div class="Title2">
                        <v-icon dark>
                            mdi-nodejs
                        </v-icon>
                        Metrics
                    </div>
                </div>

                <div class="metric2">
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Active handles
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Active handles']">
                            {{item.pm2_env.axm_monitor["Active handles"].value}}{{item.pm2_env.axm_monitor["Active handles"].unit}}
                        </div>
                    </div>
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Heap Size
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Heap Size']">
                            {{item.pm2_env.axm_monitor["Heap Size"].value}}{{item.pm2_env.axm_monitor["Heap Size"].unit}}
                        </div>
                    </div>
                </div>

                <div class="metric2">
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Active requests
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Active requests']">
                            {{item.pm2_env.axm_monitor["Active requests"].value}}{{item.pm2_env.axm_monitor["Active requests"].unit}}
                        </div>
                    </div>
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Heap Usage
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Heap Usage']">
                            {{item.pm2_env.axm_monitor["Heap Usage"].value}}{{item.pm2_env.axm_monitor["Heap Usage"].unit}}
                        </div>
                    </div>
                </div>

                <div class="metric2">
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Event Loop Latency
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Event Loop Latency']">
                            {{item.pm2_env.axm_monitor["Event Loop Latency"].value}}{{item.pm2_env.axm_monitor["Event Loop Latency"].unit}}
                        </div>
                    </div>
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Used Heap Size
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Used Heap Size']">
                            {{item.pm2_env.axm_monitor["Used Heap Size"].value}}{{item.pm2_env.axm_monitor["Used Heap Size"].unit}}
                        </div>
                    </div>
                </div>

                <div class="metric2">
                    <div class="flexUp2">
                        <div class="Mycol1">
                            Event Loop Latency p95
                        </div>
                        <div class="Mycol2" v-if="item.pm2_env.axm_monitor['Event Loop Latency p95']">
                            {{item.pm2_env.axm_monitor["Event Loop Latency p95"].value}}{{item.pm2_env.axm_monitor["Event Loop Latency p95"].unit}}
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "Dasboard",
  data() {
    return {
        ipv4:null,
        ipv6:null,
        pm2: [],
        pm3: [
        {
            "pid": 20736,
            "name": "CashierApi",
            "pm2_env": {
            "namespace": "default",
            "kill_retry_time": 100,
            "windowsHide": true,
            "username": "samos",
            "treekill": true,
            "automation": true,
            "pmx": true,
            "instance_var": "NODE_APP_INSTANCE",
            "watch": ".",
            "autorestart": true,
            "vizion": true,
            "merge_logs": true,
            "env": {
                "CashierApi": "{}",
                "PM2_HOME": "C:\\Users\\samos\\.pm2",
                "windir": "C:\\WINDOWS",
                "USERPROFILE": "C:\\Users\\samos",
                "USERNAME": "samos",
                "USERDOMAIN_ROAMINGPROFILE": "LAPTOP-9AMPTB5U",
                "USERDOMAIN": "LAPTOP-9AMPTB5U",
                "TMP": "C:\\Users\\samos\\AppData\\Local\\Temp",
                "TEMP": "C:\\Users\\samos\\AppData\\Local\\Temp",
                "SystemRoot": "C:\\WINDOWS",
                "SystemDrive": "C:",
                "SESSIONNAME": "Console",
                "PUBLIC": "C:\\Users\\Public",
                "PSModulePath": "C:\\Program Files\\WindowsPowerShell\\Modules;C:\\WINDOWS\\system32\\WindowsPowerShell\\v1.0\\Modules;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\PowerShell\\Modules\\",
                "PROMPT": "$P$G",
                "ProgramW6432": "C:\\Program Files",
                "ProgramFiles(x86)": "C:\\Program Files (x86)",
                "ProgramFiles": "C:\\Program Files",
                "ProgramData": "C:\\ProgramData",
                "PROCESSOR_REVISION": "8e0b",
                "PROCESSOR_LEVEL": "6",
                "PROCESSOR_IDENTIFIER": "Intel64 Family 6 Model 142 Stepping 11, GenuineIntel",
                "PROCESSOR_ARCHITECTURE": "AMD64",
                "PM2_USAGE": "CLI",
                "PM2_JSON_PROCESSING": "true",
                "PATHEXT": ".COM;.EXE;.BAT;.CMD;.VBS;.VBE;.JS;.JSE;.WSF;.WSH;.MSC;.PY;.PYW",
                "Path": "C:\\Users\\samos\\Desktop\\oracle_x\\bin;C:\\Users\\samos\\Desktop\\WINDOWS.X64_193000_db_home\\bin;C:\\Program Files (x86)\\Common Files\\Intel\\Shared Files\\cpp\\bin\\Intel64;C:\\WINDOWS\\system32;C:\\WINDOWS;C:\\WINDOWS\\System32\\Wbem;C:\\WINDOWS\\System32\\WindowsPowerShell\\v1.0\\;C:\\WINDOWS\\System32\\OpenSSH\\;C:\\Program Files\\Intel\\WiFi\\bin\\;C:\\Program Files\\Common Files\\Intel\\WirelessCommon\\;C:\\ProgramData\\chocolatey\\bin;C:\\Program Files\\AdoptOpenJDK\\jdk8u192-b12\\bin;C:\\Program Files\\Java\\jdk1.8.0_211\\bin;C:\\Android\\android-sdk\\tools;C:\\Android\\android-sdk\\platform-tools;C:\\Android\\android-sdk\\tools\\bin;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\Binn\\;c:\\Program Files\\Microsoft SQL Server\\110\\Tools\\Binn\\;c:\\Program Files\\Microsoft SQL Server\\110\\DTS\\Binn\\;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\Binn\\ManagementStudio\\;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\DTS\\Binn\\;C:\\Program Files (x86)\\Yarn\\bin\\;C:\\Program Files\\PuTTY\\;C:\\gradle\\bin;C:\\composer;\"C:\\Program Files (x86)\\Git\\bin;C:\\Program Files (x86)\\Git\\cmd\";C:\\Program Files\\dotnet\\;C:\\Program Files (x86)\\dotnet\\;C:\\Program Files\\Git\\cmd;C:\\Program Files\\Go\\bin;C:\\Program Files\\PostgreSQL\\14\\bin;C:\\;C:\\Users\\samos\\AppData\\Local\\Microsoft\\WindowsApps;C:\\AppServ\\Apache24\\bin;C:\\AppServ\\php7;C:\\AppServ\\MySQL\\bin;C:\\Users\\samos\\AppData\\Local\\Programs\\Microsoft VS Code\\bin;C:\\Users\\samos\\AppData\\Local\\GitHubDesktop\\bin;C:\\Users\\samos\\AppData\\Local\\Microsoft\\WindowsApps;C:\\Users\\samos\\AppData\\Local\\Yarn\\bin;C:\\Users\\samos\\AppData\\Roaming\\Composer\\vendor\\bin;\"C:\\Program Files (x86)\\Git\\bin;C:\\Program Files (x86)\\Git\\cmd\";C:\\Users\\samos\\.dotnet\\tools;C:\\Users\\samos\\go\\bin;C:\\Users\\samos\\AppData\\Roaming\\npm",
                "OS": "Windows_NT",
                "ORACLE_SID": "XE",
                "OneDriveConsumer": "C:\\Users\\samos\\OneDrive",
                "OneDrive": "C:\\Users\\samos\\OneDrive",
                "NUMBER_OF_PROCESSORS": "8",
                "LOGONSERVER": "\\\\LAPTOP-9AMPTB5U",
                "LOCALAPPDATA": "C:\\Users\\samos\\AppData\\Local",
                "JAVA_HOME": "C:\\Program Files\\AdoptOpenJDK\\jdk8u192-b12",
                "HOMEPATH": "\\Users\\samos",
                "HOMEDRIVE": "C:",
                "GOPATH": "C:\\Users\\samos\\go\\bin",
                "DriverData": "C:\\Windows\\System32\\Drivers\\DriverData",
                "DokanLibrary1_LibraryPath_x86": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\x86\\lib\\",
                "DokanLibrary1_LibraryPath_x64": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\lib\\",
                "DokanLibrary1": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\",
                "DATABASE_URL": "postgres://postgres:mangus123@localhost:5434/pos",
                "C_EM64T_REDIST11": "C:\\Program Files (x86)\\Common Files\\Intel\\Shared Files\\cpp\\",
                "ComSpec": "C:\\WINDOWS\\system32\\cmd.exe",
                "COMPUTERNAME": "LAPTOP-9AMPTB5U",
                "CommonProgramW6432": "C:\\Program Files\\Common Files",
                "CommonProgramFiles(x86)": "C:\\Program Files (x86)\\Common Files",
                "CommonProgramFiles": "C:\\Program Files\\Common Files",
                "CLASSPATH": ".;",
                "ChocolateyLastPathUpdate": "132278627183851552",
                "ChocolateyInstall": "C:\\ProgramData\\chocolatey",
                "APPDATA": "C:\\Users\\samos\\AppData\\Roaming",
                "ANDROID_SDK_ROOT": "C:\\Users\\samos\\AppData\\Local\\Android\\Sdk",
                "ANDROID_HOME": "C:\\Users\\samos\\AppData\\Local\\Android\\Sdk",
                "ALLUSERSPROFILE": "C:\\ProgramData",
                "unique_id": "c0cd3ba4-5122-41d5-9b2f-bc0bf270aec2"
            },
            "name": "CashierApi",
            "node_args": [],
            "pm_exec_path": "C:\\Users\\samos\\Desktop\\CashierApi\\index.js",
            "pm_cwd": "C:\\Users\\samos\\Desktop\\CashierApi",
            "exec_interpreter": "node",
            "exec_mode": "fork_mode",
            "instances": 1,
            "pm_out_log_path": "C:\\Users\\samos\\.pm2\\logs\\CashierApi-out.log",
            "pm_err_log_path": "C:\\Users\\samos\\.pm2\\logs\\CashierApi-error.log",
            "pm_pid_path": "C:\\Users\\samos\\.pm2\\pids\\CashierApi-3.pid",
            "km_link": true,
            "vizion_running": false,
            "NODE_APP_INSTANCE": 0,
            "CashierApi": "{}",
            "PM2_HOME": "C:\\Users\\samos\\.pm2",
            "windir": "C:\\WINDOWS",
            "USERPROFILE": "C:\\Users\\samos",
            "USERNAME": "samos",
            "USERDOMAIN_ROAMINGPROFILE": "LAPTOP-9AMPTB5U",
            "USERDOMAIN": "LAPTOP-9AMPTB5U",
            "TMP": "C:\\Users\\samos\\AppData\\Local\\Temp",
            "TEMP": "C:\\Users\\samos\\AppData\\Local\\Temp",
            "SystemRoot": "C:\\WINDOWS",
            "SystemDrive": "C:",
            "SESSIONNAME": "Console",
            "PUBLIC": "C:\\Users\\Public",
            "PSModulePath": "C:\\Program Files\\WindowsPowerShell\\Modules;C:\\WINDOWS\\system32\\WindowsPowerShell\\v1.0\\Modules;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\PowerShell\\Modules\\",
            "PROMPT": "$P$G",
            "ProgramW6432": "C:\\Program Files",
            "ProgramFiles(x86)": "C:\\Program Files (x86)",
            "ProgramFiles": "C:\\Program Files",
            "ProgramData": "C:\\ProgramData",
            "PROCESSOR_REVISION": "8e0b",
            "PROCESSOR_LEVEL": "6",
            "PROCESSOR_IDENTIFIER": "Intel64 Family 6 Model 142 Stepping 11, GenuineIntel",
            "PROCESSOR_ARCHITECTURE": "AMD64",
            "PM2_USAGE": "CLI",
            "PM2_JSON_PROCESSING": "true",
            "PATHEXT": ".COM;.EXE;.BAT;.CMD;.VBS;.VBE;.JS;.JSE;.WSF;.WSH;.MSC;.PY;.PYW",
            "Path": "C:\\Users\\samos\\Desktop\\oracle_x\\bin;C:\\Users\\samos\\Desktop\\WINDOWS.X64_193000_db_home\\bin;C:\\Program Files (x86)\\Common Files\\Intel\\Shared Files\\cpp\\bin\\Intel64;C:\\WINDOWS\\system32;C:\\WINDOWS;C:\\WINDOWS\\System32\\Wbem;C:\\WINDOWS\\System32\\WindowsPowerShell\\v1.0\\;C:\\WINDOWS\\System32\\OpenSSH\\;C:\\Program Files\\Intel\\WiFi\\bin\\;C:\\Program Files\\Common Files\\Intel\\WirelessCommon\\;C:\\ProgramData\\chocolatey\\bin;C:\\Program Files\\AdoptOpenJDK\\jdk8u192-b12\\bin;C:\\Program Files\\Java\\jdk1.8.0_211\\bin;C:\\Android\\android-sdk\\tools;C:\\Android\\android-sdk\\platform-tools;C:\\Android\\android-sdk\\tools\\bin;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\Binn\\;c:\\Program Files\\Microsoft SQL Server\\110\\Tools\\Binn\\;c:\\Program Files\\Microsoft SQL Server\\110\\DTS\\Binn\\;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\Tools\\Binn\\ManagementStudio\\;c:\\Program Files (x86)\\Microsoft SQL Server\\110\\DTS\\Binn\\;C:\\Program Files (x86)\\Yarn\\bin\\;C:\\Program Files\\PuTTY\\;C:\\gradle\\bin;C:\\composer;\"C:\\Program Files (x86)\\Git\\bin;C:\\Program Files (x86)\\Git\\cmd\";C:\\Program Files\\dotnet\\;C:\\Program Files (x86)\\dotnet\\;C:\\Program Files\\Git\\cmd;C:\\Program Files\\Go\\bin;C:\\Program Files\\PostgreSQL\\14\\bin;C:\\;C:\\Users\\samos\\AppData\\Local\\Microsoft\\WindowsApps;C:\\AppServ\\Apache24\\bin;C:\\AppServ\\php7;C:\\AppServ\\MySQL\\bin;C:\\Users\\samos\\AppData\\Local\\Programs\\Microsoft VS Code\\bin;C:\\Users\\samos\\AppData\\Local\\GitHubDesktop\\bin;C:\\Users\\samos\\AppData\\Local\\Microsoft\\WindowsApps;C:\\Users\\samos\\AppData\\Local\\Yarn\\bin;C:\\Users\\samos\\AppData\\Roaming\\Composer\\vendor\\bin;\"C:\\Program Files (x86)\\Git\\bin;C:\\Program Files (x86)\\Git\\cmd\";C:\\Users\\samos\\.dotnet\\tools;C:\\Users\\samos\\go\\bin;C:\\Users\\samos\\AppData\\Roaming\\npm",
            "OS": "Windows_NT",
            "ORACLE_SID": "XE",
            "OneDriveConsumer": "C:\\Users\\samos\\OneDrive",
            "OneDrive": "C:\\Users\\samos\\OneDrive",
            "NUMBER_OF_PROCESSORS": "8",
            "LOGONSERVER": "\\\\LAPTOP-9AMPTB5U",
            "LOCALAPPDATA": "C:\\Users\\samos\\AppData\\Local",
            "JAVA_HOME": "C:\\Program Files\\AdoptOpenJDK\\jdk8u192-b12",
            "HOMEPATH": "\\Users\\samos",
            "HOMEDRIVE": "C:",
            "GOPATH": "C:\\Users\\samos\\go\\bin",
            "DriverData": "C:\\Windows\\System32\\Drivers\\DriverData",
            "DokanLibrary1_LibraryPath_x86": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\x86\\lib\\",
            "DokanLibrary1_LibraryPath_x64": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\lib\\",
            "DokanLibrary1": "C:\\Program Files\\Dokan\\Dokan Library-1.4.1\\",
            "DATABASE_URL": "postgres://postgres:mangus123@localhost:5434/pos",
            "C_EM64T_REDIST11": "C:\\Program Files (x86)\\Common Files\\Intel\\Shared Files\\cpp\\",
            "ComSpec": "C:\\WINDOWS\\system32\\cmd.exe",
            "COMPUTERNAME": "LAPTOP-9AMPTB5U",
            "CommonProgramW6432": "C:\\Program Files\\Common Files",
            "CommonProgramFiles(x86)": "C:\\Program Files (x86)\\Common Files",
            "CommonProgramFiles": "C:\\Program Files\\Common Files",
            "CLASSPATH": ".;",
            "ChocolateyLastPathUpdate": "132278627183851552",
            "ChocolateyInstall": "C:\\ProgramData\\chocolatey",
            "APPDATA": "C:\\Users\\samos\\AppData\\Roaming",
            "ANDROID_SDK_ROOT": "C:\\Users\\samos\\AppData\\Local\\Android\\Sdk",
            "ANDROID_HOME": "C:\\Users\\samos\\AppData\\Local\\Android\\Sdk",
            "ALLUSERSPROFILE": "C:\\ProgramData",
            "unique_id": "c0cd3ba4-5122-41d5-9b2f-bc0bf270aec2",
            "status": "online",
            "pm_uptime": 1667872552863,
            "axm_actions": [
                {
                "action_name": "km:heapdump",
                "action_type": "internal",
                "arity": 2
                },
                {
                "action_name": "km:cpu:profiling:start",
                "action_type": "internal",
                "arity": 2
                },
                {
                "action_name": "km:cpu:profiling:stop",
                "action_type": "internal",
                "arity": 1
                },
                {
                "action_name": "km:heap:sampling:start",
                "action_type": "internal",
                "arity": 2
                },
                {
                "action_name": "km:heap:sampling:stop",
                "action_type": "internal",
                "arity": 1
                }
            ],
            "axm_monitor": {
                "Used Heap Size": {
                "value": "31.58",
                "type": "internal/v8/heap/used",
                "unit": "MiB",
                "historic": true
                },
                "Heap Usage": {
                "value": 93.32,
                "type": "internal/v8/heap/usage",
                "unit": "%",
                "historic": true
                },
                "Heap Size": {
                "value": "33.84",
                "type": "internal/v8/heap/total",
                "unit": "MiB",
                "historic": true
                },
                "Event Loop Latency p95": {
                "value": "14.94",
                "type": "internal/libuv/latency/p95",
                "unit": "ms",
                "historic": true
                },
                "Event Loop Latency": {
                "value": "5.16",
                "type": "internal/libuv/latency/p50",
                "unit": "ms",
                "historic": true
                },
                "Active handles": {
                "value": 5,
                "type": "internal/libuv/handles",
                "historic": true
                },
                "Active requests": {
                "value": 0,
                "type": "internal/libuv/requests",
                "historic": true
                }
            },
            "axm_options": {
                "error": true,
                "heapdump": true,
                "feature.profiler.heapsnapshot": false,
                "feature.profiler.heapsampling": true,
                "feature.profiler.cpu_js": true,
                "latency": true,
                "catchExceptions": true,
                "profiling": true,
                "metrics": {
                "http": true,
                "runtime": true,
                "eventLoop": true,
                "network": false,
                "v8": true
                },
                "standalone": false,
                "tracing": {
                "outbound": false,
                "enabled": false
                },
                "module_conf": {},
                "apm": {
                "version": "5.0.0",
                "type": "node"
                },
                "module_name": "CashierApi",
                "module_version": "5.2.2"
            },
            "axm_dynamic": {},
            "created_at": 1667872520544,
            "pm_id": 3,
            "restart_time": 20,
            "unstable_restarts": 0,
            "version": "1.0.0",
            "node_version": "16.15.1",
            "versioning": {
                "type": "git",
                "url": "https://github.com/Promtec-Innovation/CashierApi.git",
                "revision": "22156a4ca0d7c53bd0bc0f2941e39d89a94f530f",
                "comment": "division\n",
                "unstaged": true,
                "branch": "main",
                "remotes": [
                "origin"
                ],
                "remote": "origin",
                "branch_exists_on_remote": true,
                "ahead": false,
                "next_rev": "646f537997284fc14730f97eb4ea658ad083dd42",
                "prev_rev": "438da8e5e2319af65af0a6a47cd9acfc332f8c66",
                "update_time": "2022-11-08T01:55:52.993Z",
                "repo_path": "C:\\Users\\samos\\Desktop\\CashierApi"
            },
            "exit_code": 1
            },
            "pm_id": 3,
            "monit": {
            "memory": 30982144,
            "cpu": 0
            }
        }
        ]
    };
  },
  created(){
    
    this.getList()
  },
  methods:{
    getList(){
        this.$root
          .post("/pm2/GetList", {})
          .then((response) => {
            if(response.data){

                if(response.data.pm2.success){
                    let pm2 = JSON.parse(response.data.pm2.Msg)
                    this.pm2 = pm2
                    if(response.data.net){
                        let net = response.data.net
                        
                        for(var name in net){
                            if(net[name][1]){
                                if(parseInt(net[name][1].address) != 127){
                                    this.ipv4 = net[name][1].address
                                    this.ipv6 = net[name][0].address
                                }
                            }
                        }
                    }
                }
                
            }else{
                console.log(response);
            }
            setTimeout(() => {
                this.getList()
            }, 1000);
          })
    },
    verificador(val){
        let str
        if(val == undefined){
            str = 'N/A'
        }else{
            str = val
        }
        return str
    },
    timeoline(online, val){
         let str
        if(online == "online"){
            var timeup = val

            const timenow = Date.now()
            const minute = 1000 * 60;
            const hour = minute * 60;
            const day = hour * 24;
            const year = day * 365;
            var dif = timenow - timeup

            var s = parseInt(dif / 1000)
            var m = parseInt(dif / minute)
            var h = parseInt(dif / hour)
            var d = parseInt(dif / day)
            var y = parseInt(dif / year)

            let timeName
            let timeValue

            if(y > 0){
                timeName = 'year'
            timeValue = y
            }else{
                if(d > 0){
                timeName = 'dia'
                timeValue = d
            }else{
                if(h > 0){
                    timeName = 'hrs'
                    timeValue = h
                }else{
                    if(m > 0){
                    timeName = 'minute'
                        timeValue = m
                }else{
                    timeName = 'second'
                        timeValue = s
                }
                }
            }
            }

            if(timeName != 'hrs' && timeValue > 9){
                timeName = timeName + "s"
            }
           str = online + " for " + timeValue + " " + timeName
        }else{
           str = online
        }
        return str
    },
    unstaged(val){
        let conf = 'Unstaged changes'
        if(!val){
            conf = 'Specific'
        }
        return conf
    },
    httpfix(val){
        let conf = 'Conf'
        if(!val){
            conf = 'N/A'
        }
        return conf
    }
  }
};
</script>

<style scoped>
.Mycol1{
    width: 60%;
}
.Mycol2{
    width: 40%;
    text-align: right;
}
.metric2{
    flex: 1;
    min-width: 350px;
}
.header-Item strong {
  padding: 0px 8px;
  color: white;
}
.header-Item2 strong {
  padding: 0px 3px;
  color: white;
}
.Title1{
    padding: 0px 20px;
    font-size: 20px;
    height: 22px;
    font-weight: lighter;
}
.icontitle{
    padding-top: 22px;
    border-right: 2px solid #171e27;
    border-top: 2px solid #171e27;
}
.Title2{
    padding: 0px 20px;
    font-size: 20px;
    height: 22px;
    padding-top: 6px;
    font-weight: lighter;
}
.Title3{
    padding: 0px 20px;
    font-size: 13px;
    height: 22px;
    padding-top: 6px;
    font-weight: lighter;
}
.Val{
    text-align: center;
    font-size: 30px;
    padding: 10px;
    font-weight: lighter;
    margin-top: 10px;
}
.flexUp {
  display: flex;
  align-items: flex-start;
}
.flexUp2 {
  display: flex;
    align-items: flex-start;
    border: 2px solid #171e27;
    border-left: 1px;
    padding: 10px;
    font-weight: 700;
    font-size: 15px;
    border-bottom: 1px;
}


@media (max-width: 500px) {
    .icontitle{
        width: 100%;
        min-height: 50px;
        padding-top: 0px;
        border-right: 0px;
    }
}
</style>