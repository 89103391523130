<template>
    <v-toolbar
      dark
      extended
      flat
      style="
        background: rgb(0, 63, 139);
        background: linear-gradient(
          207deg,
          rgba(0, 63, 139, 1) 0%,
          rgba(29, 125, 172, 1) 31%,
          rgba(44, 129, 166, 1) 73%,
          rgba(0, 63, 139, 1) 99%
        ) !important;
      "
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>
    </v-toolbar>
</template>

<script>
export default {
    date() {
        return{

        }
    },
}
</script>

<style scoped>

</style>