<template>
  <v-stepper v-model="e1" alt-labels>
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1" editable>
        Datos del sitio
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2" :editable="validation.n2">
        Datos del evento
      </v-stepper-step>
      <v-divider></v-divider>

      <!-- <v-stepper-step step="3" :editable="validation.n3"> Paso 3 </v-stepper-step> -->
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card flat color="white lighten-4" :min-height="minh">
          <v-row style="padding: 0px 20px">
            <v-col cols="12" style="padding: 0">
              <v-radio-group v-model="radios" row>
                <template v-slot:label>
                  <div><strong>Selecciona: </strong></div>
                </template>
                <v-radio value="1">
                  <template v-slot:label>
                    <div>
                      <strong class="success--text">Sitios Disponibles</strong>
                    </div>
                  </template>
                </v-radio>
                <v-radio value="2">
                  <template v-slot:label>
                    <div>
                      <strong class="primary--text">Crear Nuevo Sitio</strong>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" v-if ="vEntorno">
              <v-select
                v-if="radios == '1'"
                v-model="id_place"
                :items="vEntorno.allPlace"
                item-text="eventplace"
                label="Sitio disponibles"
                outlined
                return-object
                @change="selectPlace()"
              ></v-select>
              <v-text-field
                v-if="radios == '2'"
                label="Nombre del sitio"
                placeholder="Escribe del sitio"
                v-model="placeX.name"
                hint="Ejemplo: Parador Chichen Itza"
                persistent-hint
                outlined
                clearable
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre del la ciudad / municipio"
                placeholder="Escribe de la ciudad / municipio"
                v-model="placeX.state"
                hint="Ejemplo: Mérida"
                persistent-hint
                outlined
                clearable
                :disabled="disabled"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Nombre del estado"
                placeholder="Escribe del estado"
                v-model="placeX.city"
                hint="Ejemplo: Yucatan"
                persistent-hint
                outlined
                clearable
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        <v-btn color="primary" @click="nextStep(1)"> Continue </v-btn>
      </v-stepper-content>

      <v-stepper-content step="2">
        <v-card flat color="white lighten-4" :min-height="minh">
          <v-row>
            <v-col cols="12" md="6" style="min-height: 215px !important" v-if="vEntorno">
              <v-select
                v-model="event"
                :items="event_config"
                item-text="name"
                label="Tipo de evento"
                outlined
                return-object
                style="padding-top: 10px !important"
              ></v-select>

              <v-text-field
                label="Nombre del evento"
                placeholder="Escribe un nombre de evento"
                v-model="name_event"
                hint="Ejemplo: Parador Chichen Itza"
                persistent-hint
                outlined
                clearable
              ></v-text-field>

              <v-select
                v-model="cata"
                :items="vEntorno.catalogue"
                item-text="name"
                label="Categoria del evento"
                outlined
                return-object
                style="padding-top: 20px !important"
              ></v-select>

            </v-col>
            <v-col class="Colcernter ContentImg">
              <div>
                <v-img
                  src="../../assets/free2.png"
                  style="width: 400px !important; height: 189px !important"
                  v-if="event.event_config === 3"
                />
                <v-img
                  src="../../assets/time.png"
                  style="width: 260px !important; height: 180px !important"
                  v-if="event.event_config === 4"
                />
              </div>
            </v-col>
            <v-col cols="12" style="padding-top: 0">
              <div v-if="event.event_config === 3" class="t_Content">
                <div>
                  <div>
                    <h2>Es un evento que cuenta con:</h2>
                  </div>
                  <div class="itemE">
                    <strong>* Venta ilimitada de boletos</strong>
                  </div>
                  <div class="itemE">
                    <strong
                      >* Acceso sin horario de restricción dentro de la hora
                      laboral</strong
                    >
                  </div>
                </div>
              </div>

              <div v-if="event.event_config === 4" class="t_Content">
                <div>
                  <div>
                    <h2>Es un evento que cuenta con:</h2>
                  </div>
                  <div class="itemE">
                    <strong>* Se accede en fecha y hora especifica</strong>
                  </div>
                  <div class="itemE">
                    <strong>* Tiene cupo limitado</strong>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-btn color="primary" @click="newProyect()"> Crear Evento</v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <!-- <v-stepper-content step="3">
        <v-card flat color="white lighten-4" :min-height="minh">
          33333333333333
        </v-card>

        <v-btn color="primary" @click="nextStep(3)"> Continue </v-btn>

        <v-btn text> Cancel </v-btn>
      </v-stepper-content> -->
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  name: "Mystepper",
  data() {
    return {
      vEntorno:null,
      cata:null,
      validation: { n2: false, n3: false },
      event: { event_config: 3, name: "Evento Regular" },
      name_event: null,
      minh: "300px",
      disabled: "disabled",
      radios: "1",
      id_place: null,
      placeX: { state: null, city: null, id_place: null, name: "" },
      e1: 1,
      steps: 3,
    };
  },
  props: ["event_config"],
  watch: {
    steps(val) {
      console.log("waht");
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
    radios(radios) {
      if (radios != "1") {
        this.placeX = { state: null, city: null, id_event: null, name: "" };
        this.disabled = null;
      } else {
        this.selectPlace();
        this.disabled = "disabled";
      }
    },
  },
  mounted(){
    this.vEntorno = this.$vEntorno
  },
  methods: {
    selectPlace() {
      if (this.id_place) {
        this.placeX.state = this.id_place.state;
        this.placeX.city = this.id_place.city;
        this.placeX.id_place = this.id_place.id_place;
        this.placeX.name = this.id_place.name;
      } else {
        this.placeX = { state: null, city: null, id_place: null, name: "" };
      }
    },
    nextStep(n) {
      let Msg;
      switch (n) {
        case 1:
          if (!this.placeX.state) {
            if (this.radios == "1") {
              Msg = "Seleccione un sitio";
            } else {
              Msg = "Escriba el nombre del sitio";
            }
          } else {
            if (!this.placeX.city) {
              Msg = "Escriba la ciudad o municipio";
            } else {
              if (!this.placeX.name) {
                Msg = "Escriba el nombre del estado";
              }
            }
          }
          break;
        case 2:
          if (!this.name_event) {
            Msg = "Escriba del evento";
          }else{
            this.validation.n2 = true
          }
          break;
      }
      if (Msg) {
        this.$root.swalAlert("warning", "", Msg);
        return false;
      }

      if (n === this.steps) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    async newProyect(){
      if(!this.name_event){
        this.$root.swalAlert('error','','Verifique los datos del evento')
        return false
      }
      if(!this.placeX.state || !this.placeX.city || !this.placeX.name){
        this.$root.swalAlert('error','','Verifique los datos del sitio')
        return false
      }
      if(!this.cata){
        this.$root.swalAlert('error','','Verifique la categoria del evento')
        return false
      }
      this.$root.post("/Admin/NewProyect", {
          event_config: this.event, 
          event:        this.name_event, 
          place:        this.placeX,
          cata:         this.cata
        }).then((response) => {
        if (response.data) {
          let info = response.data
          console.log(info);
          if(info.success == "TRUE"){
            
            this.reselect(info)
            
          }
        }
      });
    },
    async reselect(info){
      let p = await this.$root.LoadPlace();

      let id_place = this.vEntorno.allPlace.find(item => item.id_place === info.id_place);
      let datax = {id_event:info.id_event, id_place:id_place}

      console.log(datax);

      this.$set(this.$vEntorno, "EventSelect", datax);
      this.$root.swalAlert('success','','Se ha creado correctamente el evento')
    }
  },
};
</script>

<style scoped>
.ContentImg {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Colcernter {
  text-align: -webkit-center;
}
.itemE {
  padding: 10px 10px 10px 30px;
}
</style>