<template>
  <v-card class="mb-12" color="grey lighten-4" style="20px 20px 0px 20px">
    <StepperNewEvent
      :event_config="event_config"
    />
  </v-card>
</template>

<script>
import StepperNewEvent from "../Tools/StepperNewEvent.vue";

export default {
  name: "NewEvent",
  components: {
    StepperNewEvent,
  },
  data() {
    return {
      place: null,
      event_config: [],
      vEntorno:null,
    };
  },
  created() {
    this.event_config = this.$root.event_config;
  },
  methods: {
    async loadPlace() {
      this.$root.LoadPlace();
    },
  },
};
</script>

<style scoped>
.ContentImg {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.Colcernter {
  text-align: -webkit-center;
}
.t_Content {
  position: relative;
  margin-top: -37px;
  padding: 0px 40px 30px 40px;
}
.itemE {
  padding: 10px 10px 10px 30px;
}
</style>