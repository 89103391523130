<template>

<!--     <img src="images/preview/barren_bk.jpg"> -->
    <div id="preview" class="fullscre">
        
    </div>

</template>

<script>
//npm install three
//npm install three-orbitcontrols
//npm i @danielblagy/three-mmi
const OrbitControls = require('three-orbitcontrols/OrbitControls.js')
const THREE = require('three')
import MouseMeshInteraction from '@danielblagy/three-mmi'

const width = window.innerWidth
const height = window.innerHeight
let materialArray = [];
/* var camDistance1 = 0
var camDistance2 = 0
var camDistance = 0 */

export default {
    data(){
        return{

        }
    },
    mounted(){
        this.init()
    }, 
    methods:{
        init(){
            const scene = new THREE.Scene()
            const camera = new THREE.PerspectiveCamera(55,window.innerWidth/window.innerHeight,45,30000);
            camera.position.set(600, 0, 1100);

            const renderer = new THREE.WebGLRenderer({antialias:true})
            renderer.setSize(width, height)
            document.getElementById("preview").appendChild( renderer.domElement );

            const controls = new OrbitControls(camera, renderer.domElement)
            controls.keys = {
                LEFT: 37, //left arrow
                UP: 38, // up arrow
                RIGHT: 39, // right arrow
                BOTTOM: 40 // down arrow
            };
            controls.minDistance = 500;
            controls.maxDistance = 1500;
            controls.minPolarAngle = Math.PI/2;       //boquea hacia arriba la camara
            controls.maxPolarAngle = Math.PI/2;       //boquea hacia abajo la camara

            let texture_ft = new THREE.TextureLoader().load( 'images/preview/chichen_ft.jpg');
            let texture_bk = new THREE.TextureLoader().load( 'images/preview/chichen_bk.jpg');
            let texture_up = new THREE.TextureLoader().load( 'images/preview/chichen_up.jpg');
            let texture_dn = new THREE.TextureLoader().load( 'images/preview/chichen_dn.jpg');
            let texture_rt = new THREE.TextureLoader().load( 'images/preview/chichen_rt.jpg');
            let texture_lf = new THREE.TextureLoader().load( 'images/preview/chichen_lf.jpg');

            let sterr = new THREE.TextureLoader().load( 'images/preview/Street.jpg');
            
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_ft }));
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_bk }));
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_up }));
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_dn }));
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_rt }));
            materialArray.push(new THREE.MeshBasicMaterial( { map: texture_lf }));

            for (let i = 0; i < 6; i++)
            materialArray[i].side = THREE.BackSide;

            let skyboxGeo = new THREE.BoxGeometry( 10000, 10000, 10000);
            var cube = new THREE.Mesh( skyboxGeo, materialArray );
            cube.name = 'mycube'
            scene.add( cube );

            const geometry = new THREE.SphereGeometry( 500, 30, 15 );
            //const material = new THREE.MeshBasicMaterial( { color: 0xffff00 } );
            const material = new THREE.MeshBasicMaterial( { map: texture_lf } );
            const sphere = new THREE.Mesh( geometry, material );
            sphere.name = 'elem1'
            sphere.position.set(-3000, 2000, 5000); //back-side 4000/-4000

            scene.add( sphere );
            
            
            const mmi = new MouseMeshInteraction(scene, camera);

            mmi.addHandler('elem1', 'click', function(mesh) {
                alert('interactable mesh has been clicked!')
            });

           

            function animate() {
                requestAnimationFrame(animate)

                mmi.addHandler('elem1', 'mouseenter', function(mesh) {
                    document.getElementById("preview").style.cursor = "pointer";
                });

                mmi.addHandler('mycube', 'mouseenter', function(mesh) {
                    document.getElementById("preview").style.cursor = "grab";
                });

                mmi.update();
                renderer.render(scene, camera)
            }

            animate()
        }
    }
}
</script>


<style scoped>
    .fullscre{
        width: 100vw;
        height: 100vh;
        margin: 0;
        overflow: hidden;
        background: white;
    }
</style>