import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// --------------- Estilos ------------------------
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import "@babel/polyfill";
import "@mdi/font/css/materialdesignicons.css";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "animate.css";

// --------------- Componentes --------------------
import VueI18n from "vue-i18n";
import axios from "axios";
import VueCookies from "vue-cookies";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import VueHtml2Canvas from "vue-html2canvas";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { VueMaskDirective } from "v-mask";
import VueSession from "vue-session";
import VTiptapVue from "@peepi/vuetify-tiptap";

// --------------- languages --------------------
import MX from "./i18n/Spanish/index.js";
import US from "./i18n/English/index.js";
import FR from "./i18n/French/index.js";
import "./utils/global/components";
import "./utils/global/elements";

Vue.use(VueSession);
Vue.use(VueI18n);
Vue.use(VueCookies);
Vue.use(VueSweetalert2);
Vue.use(VueHtml2Canvas);
Vue.use(Vuetify);
Vue.directive("mask", VueMaskDirective);
Vue.use(VTiptapVue);

const i18n = new VueI18n({
  locale: "mx",
  messages: {
    mx: MX,
    us: US,
    fr: FR,
  },
});

// Translation provided by Vuetify (javascript)
import es from 'vuetify/lib/locale/es'
import en from 'vuetify/lib/locale/en'

const opts = [
  {
    lang: {
      locales: { es },
      current: 'es',
    },
  },
  {
    lang: {
      locales: { en },
      current: 'en',
    },
  }
];
var myTimeOut;

export default new Vuetify(opts[0]);

Vue.config.productionTip = false;

import { jsPDF } from "jspdf";
var QRCode = require("qrcode");

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  i18n,
  data: {
    allPlace: [],
    catalogue: [],
    event_sel: null,
    corp: 1,
    maxwidth: "1500px",
    maxwidth2: "1800px",
    loading: true,
    showlogin: false,
    ShowAlert: true,
    Coloralert: "error",
    IconAlert: "mdi-vuetify",
    MsgAlert:
      "Praesent congue erat at massa. Nullam vel sem. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus. Proin viverra, ligula sit amet ultrices semper, ",
    token: null,
    //myserver:'//gpstuuxan.com',
    //myserver: '//192.168.68.101',
    myserver: "//192.190.42.149",
    pathevens: "/axess/event/",
    UrlImg: "/axess/place/",
    myport: "7776",
    myport1: "7776", // http
    myport2: "9889", // https
    langs: [
      { text: "Español", locale: "mx", abbreviation: "ES" },
      { text: "English", locale: "us", abbreviation: "EN" },
    ],
    cultur: "cultur.ticket2pass.com",
    master: {},
    hostname: null,
    logo: "",
    logo_white: "",
    type: null,
    event_config: [
      { event_config: 3, name: "Entrada general" },
      { event_config: 4, name: "Evento en Grupos" },
    ],
    event_config_full: [
      { event_config: 1, name: "Evento con asientos" },
      { event_config: 2, name: "Evento sin asientos, pero con secciones" },
      { event_config: 3, name: "Entrada general" },
      { event_config: 4, name: "Evento en Grupos" },
    ],
  },
  created() {
    if (window.location.protocol == "https:") {
      this.UrlImg = "https:" + this.myserver + this.UrlImg;
      this.pathevens = "https:" + this.myserver + this.pathevens;
      this.myserver = "https:" + this.myserver + ":";
      this.myport = this.myport2;
    } else {
      this.UrlImg = "http:" + this.myserver + this.UrlImg;
      this.pathevens = "http:" + this.myserver + this.pathevens;
      this.myserver = "http:" + this.myserver + ":";
      this.myport = this.myport1;
    }
  },
  mounted() {},
  methods: {
    LoadPlace() {
      return new Promise((resolve, reject) => {
        this.$root.post("/Admin/place", {}).then((response) => {
          let info = response.data;
          if (info.success == "TRUE") {
            this.$set(this.$vEntorno, "allPlace", info.place);
            this.$set(this.$vEntorno, "catalogue", info.cata);
          } else {
            let text = 'No tiene ningun sitio configurado sitios de los eventos'
            if (info.Msg){
              text = info.Msg
            }
            this.$root.swalAlert(
              "warning",
              "",
              text
            );
          }
          resolve(true);
        });
      });
    },
    getTextlang: function () {
      let nameLang = "";
      this.langs.forEach((lang) => {
        if (lang.locale === this.$i18n.locale) nameLang = lang.abbreviation;
      });
      return nameLang;
    },
    Toast: function (icon, title, text) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        width: 280,
        timer: 1000,
        timerProgressBar: true,
        icon: icon,
        title: title,
        text: text,
      });
    },
    swalAlert: function (icon, title, text) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
      });
    },
    confimDelay: function (icon, title, text, textConfirm) {
      var self = this;
      return new Promise((resolve, reject) => {
        this.$swal({
          title,
          text,
          icon,
          showDenyButton: true,
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "primary",
          confirmButtonText: textConfirm,
          denyButtonColor: "#dd8159",
          didOpen: function () {
            var b = self.$swal.getConfirmButton();
            b.disabled = true;

            var n = 5;
            for (let i = 0; i < 5; i++) {
              setTimeout(function () {
                b.textContent = textConfirm + ` (${n})`;
                n = n - 1;
              }, i * 1000);
            }

            setTimeout(function () {
              b.disabled = false;
              b.textContent = textConfirm;
            }, 5000);
          },
        })
          .then((result) => {
            resolve(result);
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    SetToken() {
      this.$cookies.remove("user");
      this.$cookies.set("user", this.token);
    },
    post: function (endPoint, data) {
      let token = JSON.parse(localStorage.getItem("user"));
      if (token) {
        token = token.token_client;
      }
      return new Promise((resolve, reject) => {
        axios
          .post(
            this.$root.myserver + this.$root.myport + endPoint,
            { data },
            {
              headers: {
              "X-Access-Token": token,
              },
            }
          )
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 401) {
                this.showlogin = true;
                this.swalAlert("error", error.response.data);
                this.Exit();
                return false;
              }
            } else {
              this.swalAlert("error", error.response.data);
              this.Exit();
              return false;
            }
            console.log(error);
            reject(error);
          });
      });
    },
    get: function (endPoint) {
      return new Promise((resolve, reject) => {
        axios
          .get(this.$root.myserver + this.$root.myport + endPoint, {
            headers: {
              "X-Access-Token": this.$root.token.token,
              "X-corp": this.corp,
            },
          })
          .then((response) => {
            if (response.data) {
              resolve(response);
            } else {
              this.Toast("error", null, "Unexpected Error!");
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },
    nowtime() {
      var d = new Date();
      return d.getTime();
    },
    formatNum: function (value) {
      if (!value) {
        value = 0;
      }
      if (typeof value === "string") {
        value = parseFloat(value);
      }
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPrice: function (value) {
      if (!value) {
        value = 0;
      }
      if (typeof value === "string") {
        value = parseFloat(value);
      }
      value = value.toFixed(2);
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    detectmobil() {
      return new Promise((resolve) => {
        let val = false;
        if (
          navigator.userAgent.match(/Android/i) ||
          navigator.userAgent.match(/webOS/i) ||
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/iPod/i) ||
          navigator.userAgent.match(/BlackBerry/i) ||
          navigator.userAgent.match(/Windows Phone/i)
        ) {
          val = true;
        }
        resolve(val);
      });
    },
  },
  render: (h) => h(App),
}).$mount("#app");
