<template>
  <v-card flat style="height: 100vh; background: #f7f7f7; overflow: auto">
    <mytoolbar/>

    <v-card class="mx-auto MyCard" min-height="450px">
      <v-toolbar flat v-if="!card">
        <v-toolbar-title class="grey--text"> Dashboard </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-apps</v-icon>
        </v-btn>

        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-toolbar>

      <v-toolbar flat v-if="card">
        <v-toolbar-title class="grey--text">
          <div
            v-for="(item, index) in Dashboard"
            :key="'CardVIcon' + index"
            style="display: flex; align-items: center"
          >
            <template v-if="card.id == item.id">
              <v-icon style="font-size: 50px; margin-right: 5px">{{
                item.icon
              }}</v-icon
              >{{ VeriNameEvent(item) }}
            </template>
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="margin-right: 10px"
              @click="card = null"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon style="font-size: 50px">mdi-arrow-left-bold</v-icon>
            </v-btn>
          </template>
          <span>Regresar al menu</span>
        </v-tooltip>
      </v-toolbar>

      <v-divider></v-divider>
      <div style="padding: 10px">
        <div class="ContentCards" v-if="!card" >
          <div
            style="padding: 10px"
            @click="selectcard(item)"
            v-for="(item, index) in Dashboard"
            :key="'Card' + index"
          >
            <template>
              <MyCard2
                :title="item.title"
                :subtitle="item.subtitle"
                :icon="item.icon"
                :background="item.background"
                :colorIcon="item.colorIcon"
                class="box"
              />
            </template>
          </div>
        </div>

        <template v-if="card">
          <div v-if="card.id === 0">
            <CreateN/>
          </div>
          <div v-if="card.id === 1">
            <Event ref="Event"/>
          </div>
          <div v-if="card.id === 3">
            <Config/>
          </div>
        </template>

        <div style="padding: 10px">
          <!-- <Mystepper/> -->
        </div>
      </div>

      <v-alert
        :color="'#ff0058'"
        dark
        :icon="'mdi-alert'"
        border="left"
        prominent
        style="zoom: 80%"
        v-if="card === 1"
      >
        Se debe haber configurado previamente los boletos a utilizar para el
        evento en la plataforma de axess <br />
        <v-icon dark>mdi-link-variant</v-icon
        ><a
          href="https://yucatan.teamaxess.com:16500/CLICS/"
          target="_blank"
          style="color: white"
        >
          https://yucatan.teamaxess.com:16500/CLICS/
        </a>
      </v-alert>
    </v-card>
  </v-card>
</template>

<script>
import mytoolbar from "../components/mytoolbar.vue"
import MyCard2 from "../components/Tools/MyCard2.vue";
import Mystepper from "../components/Tools/Mystepper.vue";
import CreateN from '../components/create/new.vue'
import Config from '../components/config.vue'
import Event from './Events.vue'
/* import Dasboard from '../components/Tools/Dashbord.vue' */

export default {
  name: "Home",
  components: {
    mytoolbar,
    MyCard2,
    Mystepper,
    CreateN,
    Config,
    Event,
  },
  data() {
    return {
      card: null,
      Dashboard: [
        {
          id:1,
          title: "Ver Eventos",
          subtitle: "Modulo para editar o modificar eventos ya creados",
          icon: "mdi-stadium",
          background: "#4caf50",
          colorIcon: "#80e27e",
        },
        {
          id:2,
          title: "Reportes",
          subtitle:
            "Modulo de reporteria y estadisticas de las ventas de los eventos",
          icon: "mdi-television-guide",
          background: "#ff0058",
          colorIcon: "#c30043",
        },
        {
          id:3,
          title: "Configuracion",
          subtitle:
            "Modulo para configurar los datos de netpay y axess que utilizara",
          icon: "mdi-cog-outline",
          background: "#e64a19",
          colorIcon: "#993a1d",
        },
      ],
    };
  },
  created() {
        /*     {
          id:0,
          title: "Crear Evento",
          subtitle: "Modulo para crear nuevos eventos",
          icon: "mdi-collage",
          background: "#1565c0",
          colorIcon: "#5e92f3",
        }, */
    this.$root.event_sel = null
    this.$root.LoadPlace();
  },
  mounted() {
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    },
  },
  methods: {
    /* EventCreate(val){
      this.$root.event_sel = val.id_event
      this.selectcard(this.Dashboard[1])
    }, */
    VeriNameEvent(item){
      let name = item.title
      if(item.id == 1){
        //name = 'Eventos'
        this.$router.push({ name: "Eventos" });
      }
      return name
    },
    selectcard(val) {
      this.card = val;
    },
  },
};
</script>

<style scoped>
.ContentCards {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
}
.MyCard {
  width: 80% !important;
  margin-top: -34px !important;
  height: max-content;
  margin-bottom: 30px;
}

@media (max-width: 500px) {
  .MyCard {
    width: 90% !important;
    margin-top: -34px !important;
  }
  .box{
    zoom: 80%;
  }
}
</style>