<template>
  <v-stepper v-model="e1" alt-labels>
      <v-stepper-header>
        
          <v-stepper-step :complete="e1 > 1" step="1" editable>
            Datos del evento
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2" editable>
            Paso 2
          </v-stepper-step>
          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 3" step="3" editable>
            Paso 3
          </v-stepper-step>
        
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card class="mb-12" color="grey lighten-4" min-height="350px">
            

          </v-card>

          <v-btn color="primary" @click="nextStep(1)" :disabled="event.event_config === null">
            Continue
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card class="mb-12" color="grey lighten-4" min-height="200px">
            22222222222222
          </v-card>

          <v-btn color="primary" @click="nextStep(2)">
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card class="mb-12" flat min-height="200px">
            33333333333333
          </v-card>

          <v-btn color="primary" @click="nextStep(3)">
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

      </v-stepper-items>
    </v-stepper>
</template>

<script>
export default {
    name:'Mystepper',
    data () {
      return {
        e1: 1,
        steps: 3,
        
      }
    },

    watch: {
      steps (val) {
        if (this.e1 > val) {
          this.e1 = val
        }
      },
    },

    methods: {
      nextStep (n) {
        if (n === this.steps) {
          this.e1 = 1
        } else {
          this.e1 = n + 1
        }
      },
    },
}
</script>

<style scoped>
    .itemE{
        padding: 10px 10px 10px 30px;
    }
</style>